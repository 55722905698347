.home-page-component{
    background: linear-gradient(180deg, rgba(255, 184, 0, 0.27) 0%, #2090F8 100%);
    width: 100%;
    height: auto;
}

h2,p{
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
h2{
   margin-top: 6%;
   font-weight: bold;
   font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
   font-size: 48px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;

}
p{
    margin-top: 2%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.start-btn{
    width: 215px;
   height: 46px;
   display: block; margin: 0 auto;
   flex-shrink: 0;
   background-color: #1686EE;
   color: #FFF;
   border-radius: 20px;
   margin-top: 50px;
   font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   font-size: 18px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   margin-bottom: 40px;
    box-shadow: black;

}
.start-btn:disabled {
    background-color: #1686EE;
    color:white;
    cursor: not-allowed; /* Add this to show a "not-allowed" cursor on disabled state */
}

.start-btn:hover {
    background: #000000;
    color: #FFF;
}
.p1{
    width: 1000px;
    height: 240px;
    display: block; margin: 0 auto; 
    border-radius: 20px;
}
.col1{
    text-align: center;
    width:37%;
    margin-right: 6%;
}
.p2{
    margin-top: 6%;
    width: auto;
    height: 359px;
    border-radius: 10px;
}

.p3{
    margin-top: 20%;
    margin-left: 5%;
    width: auto;
    height: 359px;
    border-radius: 10px;
}
.col2{
    margin-top: 10%;
    width:40%;
    margin-left: 15%;

}
.signUp-btn{
    width: 215px;
    height: 46px;
    display: block; margin: 0 auto;
    flex-shrink: 0;
    background-color:#C29623 ;
    color: #FFF;
    border-radius: 20px;
    margin-top: 7%;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
}
.signUp-btn:hover {
     background: #000000;
     color: #FFF;
 }
.kk{
    color: #000;
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.c{
    width: 80%;
    margin-left: 8%;
    border-radius: 20px;
    background-color: #383838;
    margin-top: 8%;
}
.p4{
    border-radius: 250px;
    width: 20%;
    height: 20%;
    margin-top: 3%;
    margin-bottom: 3%;
}
h4{
    margin-top: 15%;
    margin-left: -16%;
    color: white;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}
.talent-btn{
    width: 312px;
    height: 66px;
    display: block; margin: 0 auto;
    flex-shrink: 0;
    background: linear-gradient(180deg, #2090F8 0%, #000 100%);
    stroke-width: 1px;
    stroke: #FFF;
    mix-blend-mode: hard-light;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    color: #FFF;
    border-radius: 20px;
    margin-top: 6%;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: -2%;
    margin-bottom: 5%;
}

.talent-btn:hover {
    background: #000000;
    color: #FFF;
}

.c1{
    width: 100%;
    height: 500px;
    flex-shrink: 0;
    background: linear-gradient(180deg, #A1A1A1 0%, #FFF 98.44%);
}
h3{
    text-align: center;
    font-family: Bahnschrift;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
h5{
    font-family: "Baskerville Old Face";
}
.c4{
     width: 8%;
    margin-top: 5%;
 }
.MadeBy{
    color: #000;
    text-align: center;
    font-family: Algerian;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.NP{
    color: #FFF;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
}
.c5{
    width: 8%;
    margin-left: 11%;
    margin-bottom: -0.5%;
}
.c6{
    width: 10%;
    margin-left: 10%;
   margin-bottom: 0.2%;
}

.flag{
    width: 50%;
    height: 60%;
    border-radius: 50%;
    display: block; margin: 0 auto;

}
.hg{
    display: block; margin: 0 auto;
    width:40%;
}

@media (max-width: 600px) {
    .p1, .p2, .p3 {
        display: block;
        margin: 0 auto;
        max-width: 100%; /* Assurez-vous que l'image ne dépasse pas la largeur de son conteneur */
        height: auto; /* Garantit que l'image conserve son ratio hauteur-largeur */
        border-radius: 20px;
    }
    
    .p2, .p3 {
        margin-top: 6%; /* Ajustez la marge supérieure si nécessaire */
    }
    
    .p3 {
        margin-top: 20%; /* Ajustez la marge supérieure si nécessaire */
        margin-left: 0; /* Ajustez la marge gauche si nécessaire */
    }
    
    .col2 {
        margin-top: 10%;
        width: 100%; /* Set the width to 100% */
        margin-left: 0; /* Remove the left margin */
    }
    
    .c {
        width: 100%;
        max-width: 1200px; /* Set a maximum width if needed */
        margin: 0 auto; /* Center the container */
        padding: 20px; /* Add some padding for better spacing */
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .row-container {
        width: 80%;
    }

    .row-container-column{
        margin: 0;
    }

    h4 {
        margin-left: 0;
    }
    .talent-btn {
        margin: 0;
    }

    .c1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    /* Ajoutez ces styles si nécessaire pour ajuster la marge en haut et en bas */
    .c1 h3 {
        margin-top: 20px; /* Ajustez la marge supérieure si nécessaire */
        margin-bottom: 20px; /* Ajustez la marge inférieure si nécessaire */
    }
    
    .c1 h5 {
        margin-top: 10px; /* Ajustez la marge supérieure si nécessaire */
        margin-bottom: 10px; /* Ajustez la marge inférieure si nécessaire */
    }
    
    .c1 .start-btn {
        margin-top: 20px; /* Ajustez la marge supérieure si nécessaire */
    }

    .kk {
        width: auto;
    }
}