.header-container {
    display: flex;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    height: 85px;
    flex-shrink: 0;
    background: #ffffff;
}
.logo_gp{
    width: 333px;
    height: 87px;
    flex-shrink: 0;
    top: 7px;
    left: 15px;

}
.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.menu_items.css-dev-only-do-not-override-17sses9{
    height: 47.833333px;
    margin-left: 25%;
    margin-top: 20px;
    width:525px;
    margin-right: 9%;
}
.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.menu_items.css-dev-only-do-not-override-17sses9::before{
    margin-bottom:-3%;
}

.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.css-dev-only-do-not-override-nllxry{
    height: 37.833333px;
    margin-left: 15%;
    margin-top: 20px;
    width:450px;
    margin-right: 10%;

}
.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child{
   font-family: Josefin Sans;
   font-size: 26px;
   font-style: normal;
    font-weight: 400;

}

.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child{
    margin-right: 80px;
    margin-top:3%;
}
.menu-link {
    text-decoration: none;
}

.App .ant-menu-horizontal > .ant-menu-item:hover,
.App .ant-menu-horizontal > .ant-menu-submenu:hover,
.App .ant-menu-horizontal > .ant-menu-item-active,
.App .ant-menu-horizontal > .ant-menu-submenu-active,
.App .ant-menu-horizontal > .ant-menu-item-open,
.App .ant-menu-horizontal > .ant-menu-submenu-open,
.App .ant-menu-horizontal > .ant-menu-item-selected,
.App .ant-menu-horizontal > .ant-menu-submenu-selected {
 border-bottom: 2px solid #2090F8;
 color: #2090F8;
}

.sign-in-button{
    width: 137.787px;
    height: 43px;
    border-radius: 20px;
    background: #82B4E1;
    color: #FFF;
    font-family: Josefin Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right:2%;
    margin-left:70%;
}

.sign-in-button:hover {
    background: #000000;
    color: #FFF;
}
.anticon.anticon-user.ant-dropdown-menu-item-icon{
    font-size:20px;
    margin-right:20px;
}
.anticon.anticon-logout.ant-dropdown-menu-item-icon{
    font-size:20px;
    margin-right:20px;
}

