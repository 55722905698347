@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre&family=Darker+Grotesque&family=Inter:wght@700&display=swap');

body {
    margin: 0; /* Reset default margin on the body */
    font-family: 'Inter', sans-serif;
}

#register-component {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 184, 0, 0.27) 0%, #2090F8 100%);
    width: 100%;
    height: 100vh; /* Set height to full viewport height */
}

