.about-us-component{
    background: linear-gradient(180deg, rgba(255, 184, 0, 0.27) 0%, #2090F8 100%);
    width: 100%;
    height: auto;
}

.black{
    margin-left: 38%;
    width: 25%;
    height: 25%;
}
.tx1{
    margin-left: 15%;
    margin-top: -1%;
    width: 70%;
    text-align: center;
    color: black;
    font-family: "Baskerville Old Face";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.team{
    margin-top:8%;
    text-align: center;

}
.highlight1 {
    color: #005FB6;
}

.t{
    font-size: 35px;
    font-family: "Segoe Print";
    margin-top:10%;
    margin-left: 37%;
}

.tx2{
    font-size: 35px;
    font-family: "Segoe Print";
}
.highlight2 {
    color: #FFB800;
}

.test{
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}
.t1{
    width: 230px; /* Adjust the width and height as needed */
    height: 255px;
    border-radius: 50%;

}
.highlight3{
    color: #FFFFFF;
}