.profile-page {
    width: 100%;
    height: 800px;
    margin: 0 auto;
    background: linear-gradient(180deg, rgba(255, 184, 0, 0.27) 0%, #2090F8 100%);
    padding: 20px;
}

.profile-header {
    position: relative;
    height: 300px;
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: flex-end;
    padding: 20px;
    margin-top:7%;
    margin-left:6%;
}
.fbox {
    height: 40%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    background-image: url('tt.jpeg'); /* Replace 'your-background-image-url.jpg' with the actual URL or path to your image */
    background-size: cover;
    background-position: center;
}

.profile-image-container {
    margin-right: 20px;
    width:30%;
}

.upload-button {
    cursor: pointer;
    position: relative;
    margin-left:60%;
    margin-top:-50%;
    transform: none;
    font-size: 30px;
    color: black;
    background: none;
    border: none;
    padding: 0;
}



.profile-image {
    width: 270px;
    height: 270px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #fff;
}

.default-profile-image {
    position: relative;
    width: 270px;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    background-color: #bbb;
    border-radius: 50%;
}

.user-info {
    color: #fff;
    margin-bottom:-4%;
}

.background-image-container {
    margin-top: 20px;
}

.background-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 8px;
}

.default-background-image {
    width: 100%;
    max-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
    background-color: #eee;
    border-radius: 8px;
}
/*------------------------------------------*/


.b-container {
    display: flex;
    gap: 20%;
    margin-top: 15%;
    justify-content: center; /* Center the buttons horizontally */
}

.cv-container,
.portfolio-container {
    text-align: center; /* Center the contents within each container */
}

.cv-b,
.portfolio-b {
    background-color: #0062BD;
    color: white;
    width: 250px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.file-preview {
    margin-top: 10px;
    height:50px;
    padding: 10px;
}
