.inputpdf_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* 100% de la hauteur de la vue */
    background-color: #f0f0f0; /* Couleur de fond de l'interface utilisateur */
  }
  
  label {
    margin-bottom: 10px;
  }
  
  .addFile {
    padding: 10px;
    color: white;
    cursor: pointer;
  }
  
  .addFile:hover {
    background-color: black; /* Couleur de fond du bouton au survol */
  }

  
  .file-input {
    display: none;
  }
  
  .file-input-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .file-input-wrapper label {
    display: block;
    padding: 10px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .file-input-wrapper label:hover {
    background-color: #333;
  }
  
  .file-input-wrapper span {
    color: #888;
  }
  