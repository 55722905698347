.talents-component{
    background: linear-gradient(180deg, rgba(255, 184, 0, 0.27) 0%, #2090F8 100%);
    width: 100%;
    height: 1500px;
}

.hl{
    color: #2090F8;
}
.ta{
    display: block; margin: 0 auto;
    margin-left: 10%;
    border-radius: 20px;
    margin-top: 3%;
    background: #000;
    width: 1281px;
    height: 408px;
    flex-shrink: 0;
}
.p6{
    border-radius: 50%;
    width: 7%;
    height: 7%;
    margin-top: 3%;
    margin-left: 90%;

}
.custom-input::placeholder {
    color: white;
}
